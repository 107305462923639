import React from "react";
import styled from "styled-components";

import Layout from "../components/Layout";
import Seo from "../components/Seo";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Content from "../components/Content";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
`;

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 600px;
`;

const StatusCode = styled.div`
  font-size: 112px;
  font-weight: 400;
  line-height: 1.2;
  color: ${(props) => props.theme.color.secondary.minus};
`;

const Headline = styled.div`
  font-size: 30px;
  font-weight: 400;
  color: ${(props) => props.theme.color.secondary.minus};
  margin-bottom: 10px;
`;

const Message = styled.div`
  font-weight: 400;
  color: ${(props) => props.theme.color.secondary.minus};
  text-align: center;
`;

export default () => {
  return (
    <Layout>
      <Seo title="Not Found" />
      <Container>
        <Header />
        <Wrapper>
          <Content>
            <Outer>
              <Inner>
                <StatusCode>404</StatusCode>
                <Headline>Not Found</Headline>
                <Message>
                  You may have typed the address incorrectly or you may have
                  used an outdated link. If you found a broken link from another
                  site or from this site, please{" "}
                  <a href="mailto:tom@tleef.com">email</a> me.
                </Message>
              </Inner>
            </Outer>
          </Content>
        </Wrapper>
        <Footer />
      </Container>
    </Layout>
  );
};
